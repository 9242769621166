import { Action, createReducer, on } from '@ngrx/store';

import { BasicState, buildInitialBasicState } from '@mp/shared/data-access';

import { OrganisationBenutzer } from '../..';

import { OrganisationBenutzerActions } from './organisation-benutzer.actions';

export const ORGANISATION_BENUTZER_FEATURE_KEY = 'organisation-benutzer';

export interface OrganisationBenutzerPartialState {
  readonly [ORGANISATION_BENUTZER_FEATURE_KEY]: OrganisationBenutzerState;
}

export type OrganisationBenutzerState = BasicState<OrganisationBenutzer>;
export const initialState: OrganisationBenutzerState = buildInitialBasicState();

const reducer = createReducer(
  initialState,

  on(
    OrganisationBenutzerActions.COMPONENT.loadSingle,
    OrganisationBenutzerActions.COMPONENT.loadAll,
    OrganisationBenutzerActions.COMPONENT.create,
    OrganisationBenutzerActions.COMPONENT.update,

    (state): OrganisationBenutzerState => ({
      ...state,
      isLoading: true,
    }),
  ),

  on(
    OrganisationBenutzerActions.API.loadedSingleSuccessfully,

    (state, action): OrganisationBenutzerState => ({
      ...state,
      isLoading: false,
      selected: action.loadedBenutzer,
    }),
  ),

  on(
    OrganisationBenutzerActions.API.loadedAllSuccessfully,

    (state, action): OrganisationBenutzerState => ({
      ...state,
      isLoading: false,
      entities: action.loadedBenutzerPage.data,
      pagination: action.loadedBenutzerPage.pagination,
    }),
  ),

  on(
    OrganisationBenutzerActions.API.createdSuccessfully,

    (state, action): OrganisationBenutzerState => ({
      ...state,
      isLoading: false,
      entities: [action.createdBenutzer, ...(state.entities ?? [])],
      selected: undefined,
    }),
  ),

  on(
    OrganisationBenutzerActions.API.updatedSuccessfully,

    (state, action): OrganisationBenutzerState => {
      const entitiesWithUpdatedEntityReplaced = [
        ...(state.entities ?? []).map((entity) =>
          entity.id === action.updatedBenutzer.id ? action.updatedBenutzer : entity,
        ),
      ];

      return {
        ...state,
        isLoading: false,
        entities: entitiesWithUpdatedEntityReplaced,
        selected: undefined,
      };
    },
  ),

  on(
    OrganisationBenutzerActions.API.loadedAllUnsuccessfully,
    OrganisationBenutzerActions.API.loadedSingleUnsuccessfully,
    OrganisationBenutzerActions.API.createdUnsuccessfully,
    OrganisationBenutzerActions.API.updatedUnsuccessfully,

    (state): OrganisationBenutzerState => ({
      ...state,
      isLoading: false,
    }),
  ),

  on(
    OrganisationBenutzerActions.API.canceledUpdate,
    OrganisationBenutzerActions.API.canceledCreate,

    (state): OrganisationBenutzerState => ({
      ...state,
      isLoading: false,
      selected: undefined,
    }),
  ),
);

export function organisationBenutzerReducer(
  state: OrganisationBenutzerState | undefined,
  action: Action,
): OrganisationBenutzerState {
  return reducer(state, action);
}
