import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ORGANISATION_BENUTZER_FEATURE_KEY, OrganisationBenutzerState } from './organisation-benutzer.reducer';

const getOrganisationBenutzerState = createFeatureSelector<OrganisationBenutzerState>(
  ORGANISATION_BENUTZER_FEATURE_KEY,
);

const IS_LOADING = createSelector(getOrganisationBenutzerState, (state) => state.isLoading);

const LIST = createSelector(getOrganisationBenutzerState, (state) => state.entities);

const SELECTED = createSelector(getOrganisationBenutzerState, (state) => state.selected);

export const organisationBenutzerSelectors = {
  IS_LOADING,
  LIST,
  SELECTED,
};
